import React, { useState } from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import Footer from "../../components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function OtherInitiatives() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);
  const [isOpen17, setIsOpen17] = useState(false);
  const [isOpen18, setIsOpen18] = useState(false);
  const [isOpen19, setIsOpen19] = useState(false);
  const [isOpen20, setIsOpen20] = useState(false);
  const [isOpen21, setIsOpen21] = useState(false);
  const [isOpen22, setIsOpen22] = useState(false);
  const [isOpen23, setIsOpen23] = useState(false);
  const [isOpen24, setIsOpen24] = useState(false);
  const [isOpen25, setIsOpen25] = useState(false);
  const [isOpen26, setIsOpen26] = useState(false);
  const [isOpen27, setIsOpen27] = useState(false);
  const [isOpen28, setIsOpen28] = useState(false);
  const [isOpen29, setIsOpen29] = useState(false);
  const [isOpen30, setIsOpen30] = useState(false);

  const paragraphStyles1 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  const paragraphStyles2 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles3 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles4 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles5 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles6 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles7 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles8 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles9 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles10 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles11 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles12 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles13 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles14 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles15 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles16 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles17 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles18 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles19 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles20 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles21 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles22 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles23 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles24 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles25 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles26 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles27 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles28 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles29 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };
  const paragraphStyles30 = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div>
      <NavBar />

      {/* session - 1 */}

      <center>
        <div>
          <h1 className="other-initiatives">OTHER INITIATIVES</h1>
        </div>

        <div className="other-initiatives-para-box">
          {/* //// 02 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/best_web_award.webp"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES AND BEIRA BRUSH WEBSITES TRIUMPH AT THE
                      BESTWEB.LK 2024 AWARDS
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      August, 21 2024
                    </h3>
                    <p style={isOpen1 ? null : paragraphStyles1}>
                      <p className="other-initiatives-read-more-para">
                        The website of Eco Spindles and Beira Brush,
                        subsidiaries of BPPL Holdings, a leading player in the
                        brush ware and filament export manufacturing industry,
                        achieved remarkable recognition in their digital journey
                        at the BestWeb.lk 2024 awards held recently.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Acknowledging their exceptional online presence, Eco
                        Spindles proudly claimed the Gold Award and Beira Brush
                        secured the Silver Award at the BestWeb.lk 2024 awards.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Both companies won the accolades in the Manufacturing &
                        Industrial category. The achievement demonstrates BPPL’s
                        commitment to innovation and excellence across all
                        platforms.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The annual BestWeb.lk competition is conducted by LK
                        Domain Registry to promote and popularise the web
                        presence of institutions in Sri Lanka. At the award
                        ceremony which took place recently, over 500 applicants
                        vied to take the top spots for the best lk domain
                        websites not just in their relevant category but also in
                        the overall design awards.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        An expert panel of judges, comprising web experts and
                        industry professionals judged the websites on
                        creativity, quality of graphic design, artistry,
                        technological expertise and the quality of the content
                        made available to the user.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen1(!isOpen1)}
                          >
                            {isOpen1 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/drop_off_bins.webp"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES COLLABORATES WITH COCA-COLA TO DONATE THE
                      DROP-OFF BINS TO SL PARLIAMENT
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      June 05, 2024
                    </h3>
                    <p style={isOpen2 ? null : paragraphStyles2}>
                      <p className="other-initiatives-read-more-para">
                        As part of its CSR activities, Eco Spindles collaborated
                        with Coca-Cola to donate the drop-off bins to SL
                        Parliament for the collection of recyclable plastics.
                        The initiative demonstrated a strong commitment to
                        environmental sustainability and community engagement.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The program was coordinated by MP Ajith Mannapperuma of
                        the Sectoral Oversight Committee on Environment, Natural
                        Resources & Sustainable Development of the Sri Lankan
                        Parliament. The project was launched in celebrate of
                        World Environment Day.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The event was graced by the Speaker of the Parliament,
                        Mr. Mahinda Yapa Abeywardena, who highlighted the
                        significance of the environmental effort. Additionally,
                        Eco Spindles donated Tip Top household brooms and brush
                        products made from recycled plastics for the
                        Parliament’s housekeeping purposes.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen2(!isOpen2)}
                          >
                            {isOpen2 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 03 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/sri_pada_cleanup.webp"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES ORGANIZED THE SRI PADA CLEANUP CONTRIBUTING
                      TOWARDS ENVIRONMENTAL CONSERVATION
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      March 28, 2024
                    </h3>
                    <p style={isOpen3 ? null : paragraphStyles3}>
                      <p className="other-initiatives-read-more-para">
                        Demonstrating a commitment to promote environmental
                        conservation, Eco Spinldes organized the Sri Pada
                        Cleanup Event. Two cleanup events were successfully
                        completed in March and May 2024, in cooperation with
                        Hemas Holdings.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The final cleanup event, held on May 28th, saw
                        participation from the Department of Local Government of
                        Central Province and five local authorities. The
                        initiative was affiliated with the Department of
                        Wildlife Conservation in Nallathanniya, contributing to
                        their ongoing efforts. On average, the Department of
                        Wildlife collects around three tons of PET bottles
                        during the pilgrim season which then will be collected
                        by Eco Spindles for upcycling.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen3(!isOpen3)}
                          >
                            {isOpen3 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/e_bike.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES INTRODUCES E-BIKES AT MATERIAL RECOVERY
                      FACILITIES
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      March 28, 2024
                    </h3>
                    <p style={isOpen4 ? null : paragraphStyles4}>
                      <p className="other-initiatives-read-more-para">
                        Reinforcing Eco Spindles’ commitment to sustainability,
                        the company introduced an innovative solution to urban
                        sustainability with the purchase of e-bikes. In the
                        trial stage, five e-bikes equipped with recyclable
                        material collection trailers, were distributed among the
                        five Material Recovery Facilities (MRFs) located in
                        Colombo, Katunayake, Matara, Wadduwa and Anuradapura.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The initiative aims to reduce fuel costs and empower
                        individuals in underserved communities to collect
                        recyclables efficiently, additionally promoting both
                        environmental sustainability and economic empowerment.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen4(!isOpen4)}
                          >
                            {isOpen4 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 04 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/wasate_management_gampaha.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES INITIATES GAMPAHA DISTRICT DROP OFF BIN
                      PROJECT TO IMPROVE WASTE MANAGEMENT
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      January 29, 2024
                    </h3>
                    <p style={isOpen5 ? null : paragraphStyles5}>
                      <p className="other-initiatives-read-more-para">
                        In a major initiative to improve waste management in the
                        region, Eco Spindles organised the Gampaha District
                        Drop-Off Bin Project. The effort was undertaken in
                        collaboration with 10 divisional secretariats in the
                        Gampaha district
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The program was coordinated by MP Ajith Mannapperuma of
                        the Sectoral Oversight Committee on Environment, Natural
                        Resources & Sustainable Development of the Sri Lankan
                        Parliament. Through this initiative, Eco Spindles
                        demonstrated its commitment to environmental
                        sustainability and community engagement, fostering a
                        cleaner and healthier environment in the Gampaha
                        district.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Coca-Cola contributed 10 bins to further support the
                        project, which were strategically placed to promote
                        effective waste management and recycling practices.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen5(!isOpen5)}
                          >
                            {isOpen5 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/material_recovery_hemas.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES COLLABORATES WITH HEMAS GROUP TO COMMISSION
                      TWO MATERIAL RECOVERY FACILITIES
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      January 24, 2024
                    </h3>
                    <p style={isOpen6 ? null : paragraphStyles6}>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles in collaboration with the Hemas Group, a
                        diversified corporate in Sri Lanka, launched a
                        significant initiative to enhance waste management and
                        recycling efforts.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        In February 2024, two Material Recovery Facilities
                        (MRFs) were opened in Colombo and Ampara. These
                        facilities focus on collecting PET bottles and other
                        plastic waste, converting them into valuable resources
                        such as high-quality yarn and monofilaments through Eco
                        Spindles’ advanced recycling processes.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The initiative underscores Eco Spindles’s commitment to
                        sustainability and environmental stewardship while also
                        fostering economic opportunities for local communities
                        in Colombo and Ampara. Through this collaborative
                        effort, Eco Spindles and the Hemas Group aim to promote
                        a cleaner and more sustainable future.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen6(!isOpen6)}
                          >
                            {isOpen6 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 05 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/book_distribution.webp"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      BPPL HOLDINGS HOSTS BOOK DISTRIBUTION DAY TO SUPPORT
                      EDUCATION OF EMPLOYEES’ CHILDREN
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      December 10, 2023
                    </h3>
                    <p style={isOpen7 ? null : paragraphStyles7}>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings Book Distribution Day was a heartwarming
                        initiative aimed at supporting the education of
                        employees’ children.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        During this event, the company donated school books to
                        young learners, with the goal of enriching their
                        learning experiences and strengthening their educational
                        journeys. By providing these essential resources, BPPL
                        Holdings not only created valuable opportunities for
                        academic growth but also reinforced its commitment to
                        nurturing the development of the next generation.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen7(!isOpen7)}
                          >
                            {isOpen7 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/Screenshot-351.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      BPPL HOLDINGS HONOURED WITH FORBES ASIA’S “BEST UNDER A
                      BILLION” AWARD
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      December 01, 2023
                    </h3>
                    <p style={isOpen8 ? null : paragraphStyles8}>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings PLC, a leading player in the brush ware
                        and filament export manufacturing industry, was awarded
                        by Forbes Asia as “Asia’s Best Under a Billion” for the
                        year 2021. The award ceremony took place on 21 November
                        2023 in Manila, Philippines.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Forbes Asia’s “Best Under a Billion” award recognises
                        companies demonstrating exceptional long-term
                        sustainable performance across a range of criteria. In a
                        rigorous selection process, from a pool of over 20,000
                        publicly traded companies in the Asia-Pacific region
                        with annual sales exceeding $ 10 million but below $ 1
                        billion, BPPL Holdings was among 200 outstanding
                        companies chosen for this prestigious award.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings Primary Shareholder and Managing Director,
                        Dr. Anush Amarasinghe said: “We are honoured to receive
                        ‘Asia’s Best Under a Billion’ award from Forbes Asia,
                        which underscores our commitment to sustainable business
                        practices and long-term growth. This recognition is a
                        testament to the hard work and dedication of our team in
                        consistently delivering value to our stakeholders while
                        adhering to the highest standards of corporate
                        responsibility. The award spurs us to do more in the
                        environmental and social sustainability space we occupy
                        by adding more such assets to our portfolio.”
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen8(!isOpen8)}
                          >
                            {isOpen8 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 06 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/waste_to_value.webp"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES IMPLEMENTS GROUNDBREAKING ‘WASTE TO VALUE’
                      INITIATIVE IN KATARAGAMA
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      July 04, 2023
                    </h3>
                    <p style={isOpen9 ? null : paragraphStyles9}>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles implemented a groundbreaking ‘Waste to
                        Value’ initiative in Kataragama, showcasing its
                        commitment to sustainability and environmental
                        stewardship.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The initiative focused on the collection and recycling
                        of plastic waste, effectively transforming it into
                        valuable resources. The project not only mitigated the
                        environmental impact of plastic waste but also generated
                        economic opportunities for the local community.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Through this sustainable initiative, Eco Spindles has
                        demonstrated a proactive approach to corporate social
                        responsibility, promoting a cleaner and more sustainable
                        future for Kataragama and its surrounding areas.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The project included clean-up activities at the Devala
                        Premises, the riverbank of Menik Ganga, Wedasiti Kanda,
                        and Kataragama Pooja Buumi. The event was supported by
                        the Ruhunu Maha Kataragama Devalaya, the Soba Mithuru
                        Youth Society, and corporate partner Hemas Holdings.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen9(!isOpen9)}
                          >
                            {isOpen9 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/material-recovery-1-768x513-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      MATERIAL RECOVERY FACILITY COMMISSIONED IN NEGOMBO THROUGH
                      THE FUNDING AND SUPPORT OF ECO SPINDLES, THE COCA-COLA
                      FOUNDATION AND JANATHAKSHAN
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      March 26, 2023
                    </h3>
                    <p style={isOpen10 ? null : paragraphStyles10}>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles Pvt Ltd., the nation’s largest plastics
                        up-cycler and a fully owned subsidiary of BPPL Holdings
                        PLC, has always advocated the proper disposal and
                        collection of plastics in and around the island.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles employs several different methods of
                        plastic collection including, working with corporate
                        partners who invest in waste plastic collection bins,
                        waste plastic collectors, and via Material Recovery
                        Facilities (MRFs). Through these methods the company
                        supports many programs to create awareness of proper
                        methods of plastic disposal.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The company also greatly supports schools around the
                        country to organize projects to create awareness of
                        proper plastic disposal. So far, over 100 schools around
                        the country have organized their own initiatives which
                        were supported by Eco Spindles.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        One such project ‘Bothal Pancho’ was implemented
                        recently by students of Delgalle Primary School who
                        organized their own creative campaign to advocate proper
                        disposal and collection of plastic. The students
                        organized a ‘Perahara’ which depicted the importance of
                        collecting, disposing, and recycling plastic the correct
                        manner.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Dr. Anush Amarasinghe, Chief Executive Officer of BPPL
                        Holdings PLC shared his thoughts: “It is wonderful to
                        see schools all around the country implement their own
                        initiatives to educate and create awareness of the
                        importance of collecting and disposing of plastics. We
                        are fully prepared to extend our fullest support to
                        these initiatives as educating the future generation is
                        of paramount importance.”
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The company also organizes educational tours and
                        programs at their facilities for students, where
                        students can learn and experience the process of plastic
                        turned in to high quality yarn and brushes. The products
                        including the yarn, brushes and more are then sold to a
                        multitude of international brands, which generates much
                        needed foreign income.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles fully extends its support to these
                        campaigns and initiatives via educational programs for
                        students and schools in rural communities and in
                        Colombo, in which they showcase the importance of proper
                        methods of collection and disposal of plastics are to
                        the environment and demonstrate how plastic can be given
                        a new life via upcycling and recycling.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        In this initiative, we partnered with the Asia
                        Foundation and Coca-Cola to establish plastic collection
                        centers in 4 locations covering Biyagama, Galle,
                        Batticaloa and Vavuniya. These centers provide jobs to
                        local communities and educates them about the process
                        and benefits of methodical and efficient recycling.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        These collection centers form a focal point for our
                        sustainability efforts and education. In time we hosted
                        number of outreach and training events for nearby
                        marginalized groups , with the ultimate goal being
                        employment in our facilities. They not only learn the
                        value of recycling – they live and earn it every day at
                        their new jobs.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        By bringing these men and women into the recycling
                        economy, we hope their experience with sustainability
                        can be further shared with the wider community.
                        Providing the knowledge and tools to recycle correctly
                        is invaluable and sets the foundation for a sustainable
                        future.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen10(!isOpen10)}
                          >
                            {isOpen10 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 07 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/elephant-house-1-1.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES AND ELEPHANT HOUSE JOIN FORCES TO CLEAN UP
                      SACRED AND SCENIC ADAM’S PEAK
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      March 11, 2023
                    </h3>
                    <p style={isOpen11 ? null : paragraphStyles11}>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles in collaboration with Gunadamin Elephant
                        House, the CSR arm of Elephant House recently conducted
                        a special plastic pollution clean-up project to clean up
                        Adam’s Peak (Sri Pada) – one of Sri Lanka’s most famous
                        and scenic sites, sacred to all of Sri Lanka’s major
                        religious groups.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The initiative, which took place on 17 February, is also
                        designed to create ongoing awareness about responsible
                        PET disposal and the benefits of recycling for a more
                        robust ecosystem. The Adam’s Peak clean-up project is an
                        important step towards responsible waste management and
                        environmental conservation.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Along with Gunadamin Elephant House and Eco Spindles,
                        the initiative is also being supported by the Maskeliya
                        Urban Council, Seethagagulagame Grama Niladari Division,
                        the Maskeliya Association of Merchants (Welenda
                        Sangamaya), and local government stakeholders. All
                        disposed of PET and other plastic will then be collected
                        and recycled by Eco Spindles Ltd.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles CEO Recycling Prasantha Malimbadage
                        expressed his excitement about the partnership saying,
                        “It’s great to see the private sector working towards
                        responsible waste management and creating sustainable
                        solutions. This partnership is an example of what we can
                        achieve when we work together to protect our
                        environment.”
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The partnership between Elephant House and Eco Spindles,
                        along with the support of the Maskeliya community and
                        government stakeholders, is a testament to the
                        commitment of the private sector towards creating a
                        sustainable and beautiful natural environment.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen11(!isOpen11)}
                          >
                            {isOpen11 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/Article-1-1-1-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES TO SUPPORT SCHOOLS IN INITIATIVES THAT
                      ADVOCATE PLASTIC DISPOSAL AND COLLECTION
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      February 2, 2023
                    </h3>
                    <p style={isOpen12 ? null : paragraphStyles12}>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles Pvt Ltd., the nation’s largest plastics
                        up-cycler and a fully owned subsidiary of BPPL Holdings
                        PLC, has always advocated the proper disposal and
                        collection of plastics in and around the island.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles employs several different methods of
                        plastic collection including, working with corporate
                        partners who invest in waste plastic collection bins,
                        waste plastic collectors, and via Material Recovery
                        Facilities (MRFs). Through these methods the company
                        supports many programs to create awareness of proper
                        methods of plastic disposal.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The company also greatly supports schools around the
                        country to organize projects to create awareness of
                        proper plastic disposal. So far, over 100 schools around
                        the country have organized their own initiatives which
                        were supported by Eco Spindles.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        One such project ‘Bothal Pancho’ was implemented
                        recently by students of Delgalle Primary School who
                        organized their own creative campaign to advocate proper
                        disposal and collection of plastic. The students
                        organized a ‘Perahara’ which depicted the importance of
                        collecting, disposing, and recycling plastic the correct
                        manner.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Dr. Anush Amarasinghe, Chief Executive Officer of BPPL
                        Holdings PLC shared his thoughts: “It is wonderful to
                        see schools all around the country implement their own
                        initiatives to educate and create awareness of the
                        importance of collecting and disposing of plastics. We
                        are fully prepared to extend our fullest support to
                        these initiatives as educating the future generation is
                        of paramount importance.”
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The company also organizes educational tours and
                        programs at their facilities for students, where
                        students can learn and experience the process of plastic
                        turned in to high quality yarn and brushes. The products
                        including the yarn, brushes and more are then sold to a
                        multitude of international brands, which generates much
                        needed foreign income.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles fully extends its support to these
                        campaigns and initiatives via educational programs for
                        students and schools in rural communities and in
                        Colombo, in which they showcase the importance of proper
                        methods of collection and disposal of plastics are to
                        the environment and demonstrate how plastic can be given
                        a new life via upcycling and recycling.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        In this initiative, we partnered with the Asia
                        Foundation and Coca-Cola to establish plastic collection
                        centers in 4 locations covering Biyagama, Galle,
                        Batticaloa and Vavuniya. These centers provide jobs to
                        local communities and educates them about the process
                        and benefits of methodical and efficient recycling.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        These collection centers form a focal point for our
                        sustainability efforts and education. In time we hosted
                        number of outreach and training events for nearby
                        marginalized groups , with the ultimate goal being
                        employment in our facilities. They not only learn the
                        value of recycling – they live and earn it every day at
                        their new jobs.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        By bringing these men and women into the recycling
                        economy, we hope their experience with sustainability
                        can be further shared with the wider community.
                        Providing the knowledge and tools to recycle correctly
                        is invaluable and sets the foundation for a sustainable
                        future.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen12(!isOpen12)}
                          >
                            {isOpen12 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 08 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/econewslatest-2.png"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      HDFC BANK JOINS ECOSPINDLES TO BOOST PLASTIC RECYCLING
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      November 1, 2022
                    </h3>
                    <p style={isOpen13 ? null : paragraphStyles13}>
                      <p className="other-initiatives-read-more-para">
                        To reduce and recycle plastic waste, and bid farewell to
                        the plastic menace, the HDFC Bank partnered Eco Spindles
                        recently.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The programme will place plastic waste collection bins
                        at selected HDFC Bank branches, where the bank’s
                        customers can dispose any recyclable plastic waste such
                        as empty PET water/ soft drink bottles, hand sanitiser/
                        hand wash bottles, empty plastic containers/boxes.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The ‘disposed plastic’ will then be separated and will
                        undergo a recycling process that will result in the
                        production of plastic – based raw materials such as
                        bristles and yarn, ensuring customers are you disposing
                        plastic waste in an environment-friendly manner but also
                        are contributing towards recycling as a responsible
                        citizen.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The inaugural ceremony and signing of the MoU were
                        graced by the Chairman of HDFC Bank, Wasanthi
                        Manchanayake, CEO, Palitha Gamage and Senior Management
                        from Eco Spindles.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Commenting on the initiative, Dr. Anush Amarasinghe,
                        MD/CEO, BPPL Holdings PLC said, “As Sri Lanka’s largest
                        plastics’ recycler, we are extremely pleased to have
                        signed an important MoU with HDFC supporting their
                        initiative to promote environmental protection,
                        delivering sustainable value to their customers as well
                        as contributing to saving the planet.”
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen13(!isOpen13)}
                          >
                            {isOpen13 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/dr_anush_amarasinghe-2.png"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      BPPL SECURES US$ 15MN LONG-TERM FUNDING FROM DFC
                    </h2>
                    <h3 className="other-initiatives-2nd-topic">
                      March 25, 2022
                    </h3>
                    <p style={isOpen14 ? null : paragraphStyles14}>
                      <p className="other-initiatives-read-more-para">
                        Sri Lanka’s plastic recycling capabilities and
                        infrastructure are set to soar with BPPL Holdings PLC,
                        the country’s pioneer in the field, securing long-term
                        funding of US$ 15 million from the International
                        Development Finance Corporation (DFC) of the United
                        States of America.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The funds raised from DFC, the development finance
                        agency of the United States Government, will enable BPPL
                        to expand its monofilament and polyester yarn
                        operations, which use plastic waste as its primary raw
                        material.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        This will generate greater export earnings for Sri Lanka
                        whilst addressing the hazardous practices of plastic
                        disposal-one of the country’s critical environmental
                        problems.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The new funding will be used to expand operations to
                        double the number of PET plastic bottle collection
                        centres and the company’s bottle washing capacity, and
                        to enhance monofilament and polyester yarn production by
                        40 percent and 55 percent per annum, respectively, over
                        the next 4-5 years.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        “BPPL’s success in securing this funding from one of the
                        world’s leading providers of development finance is a
                        testament to our strength and stability, as well as an
                        endorsement of our mission,” BPPL MD/CEO Dr. Anush
                        Amarasinghe said.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen14(!isOpen14)}
                          >
                            {isOpen14 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 09 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/Stakeholders-from-Coca-Cola-Beverages-Sri-Lanka-Ltd.-Road-Development-Authority-RDA-and-Eco-Spindles-Pvt-Ltd.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      COMMUNITY EMPOWERMENT; WITH THE ASIA FOUNDATION AND
                      COCA-COLA
                    </h2>
                    <p style={isOpen15 ? null : paragraphStyles15}>
                      <p className="other-initiatives-read-more-para">
                        Here at Eco Spindles, sustainability is a multi-layered
                        solution to waste: We strive and succeed at multiple
                        socially beneficial outcomes while producing our own
                        high-quality recycled products. This holistic approach
                        fosters a cooperative sustainability effort from the
                        start (citizens) to the end (Eco Spindles) of a plastic
                        product’s initial lifecycle.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        In this initiative, we partnered with the Asia
                        Foundation and Coca-Cola to establish plastic collection
                        centers in 4 locations covering Biyagama, Galle,
                        Batticaloa and Vavuniya. These centers provide jobs to
                        local communities and educates them about the process
                        and benefits of methodical and efficient recycling.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        These collection centers form a focal point for our
                        sustainability efforts and education. In time we hosted
                        number of outreach and training events for nearby
                        marginalized groups , with the ultimate goal being
                        employment in our facilities. They not only learn the
                        value of recycling – they live and earn it every day at
                        their new jobs.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        By bringing these men and women into the recycling
                        economy, we hope their experience with sustainability
                        can be further shared with the wider community.
                        Providing the knowledge and tools to recycle correctly
                        is invaluable and sets the foundation for a sustainable
                        future.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen15(!isOpen15)}
                          >
                            {isOpen15 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/1d6606e9-citizen.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      BPPL HOLDINGS TRIUMPHS AT ‘BEST CORPORATE CITIZEN
                      SUSTAINABILITY AWARD 2021’ WINNING FIRST RUNNER-UP AWARD
                    </h2>
                    <p style={isOpen16 ? null : paragraphStyles16}>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings PLC, Sri Lanka’s largest plastics recycler
                        equipped with one of only two plants in the world
                        capable of creating polyester yarn directly from
                        recycled PET plastic flakes, was presented the First
                        Runner-up award for companies with an annual turnover
                        below Rs. 15 billion, at the ‘Best Corporate Citizen
                        Sustainability Award 2021’, concluded recently.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The annual awards organized by the Ceylon Chamber of
                        Commerce, the country’s premier business chamber,
                        recognized sustainable champions of the local corporate
                        world.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings’ award demonstrates recognition for the
                        company’s use of advanced sustainable technologies and
                        practices that mitigate pollution and waste and
                        commitment to supporting a sustainable world.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings PLC has been instrumental in the
                        manufacturing of sustainable production of eco-friendly
                        products since inception and through subsidiaries Beira
                        Brush (Private) Limited and Eco-Spindles (Private)
                        Limited continues to create value with a conscience.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="https://www.dailymirror.lk/business-news/ComBank-wins-Best-Corporate-Citizen-Sustainability-Award-2021/273-225413">
                          https://www.dailymirror.lk/business-news/ComBank-wins-Best-Corporate-Citizen-Sustainability-Award-2021/273-225413
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen16(!isOpen16)}
                          >
                            {isOpen16 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 10 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/c748d2c8-fashion_summit-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES ADDRESSES 6TH RESPONSIBLE FASHION SUMMIT ON
                      ‘INFLUENCE CIRCULARITY IN TEXTILE INNOVATION’
                    </h2>
                    <p style={isOpen17 ? null : paragraphStyles17}>
                      <p className="other-initiatives-read-more-para">
                        Nalaka Seneviratne, CEO Eco Spindles Yarn, shared his
                        views on the ‘Influence of Circularity in Textile
                        Innovation’ at a recent panel discussion during the 6th
                        Responsible Fashion Summit held in Colombo.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Joining him in the discussion was Moyne Perera,
                        Stretchline, Paige Earlam, Plexus-Cotton, UK & Africa
                        and Joy Nunn, Purfi US & Belgium.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The Responsible Fashion Summit is an annual event
                        presented jointly by Sri Lanka Apparel and Sri Lanka’s
                        Joint Apparel Association Forum (JAAF) was held for the
                        6th consecutive year.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        This year’s edition themed ‘‘Circularity-in-Action’,
                        focused on the progressive journey towards achieving
                        circularity within the supply chain of the apparel
                        sector. The summit comprised four panels led by a set of
                        elite panelists representing both the local and global
                        fashion industry. Eco Spindles was among the main
                        sponsors in supporting the summit.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Addressing the audience, Nalaka discussed Eco Spindles
                        contribution in reducing its carbon footprint through
                        innovative technology converting post-consumer PET
                        plastics into polyester yarn while also reducing the use
                        of water and energy consumption. He also shared the
                        company’s next innovation drive of recycling waste
                        yarn/fabric back into polyester yarn.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        He also spoke on the need to expand partnerships and
                        collaborations among government, public and private
                        stakeholders to create greater awareness on waste
                        segregation and collection mechanisms.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen17(!isOpen17)}
                          >
                            {isOpen17 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/ifs_change_for_good-1-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES: ONLY COMPANY IN ASIA TO WIN GLOBAL IFS
                      CHANGE FOR GOOD SUSTAINABILITY AWARD
                    </h2>
                    <p style={isOpen18 ? null : paragraphStyles18}>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles (Private) Limited, a subsidiary of BPPL
                        Holdings PLC was triumphant in becoming the only Asian
                        company to receive an IFS Change for Good Sustainability
                        Award.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        IFS, a renowned international cloud enterprise solutions
                        provider, introduced the IFS Change for Good
                        Sustainability Awards in July ‘21. The annual awards
                        hope to highlight the outstanding work by IFS clients to
                        make their businesses more sustainable while encouraging
                        others to do the same.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles has recycled over 380 million PET bottles
                        since it commenced recycling operations in 2011.
                        Comments from the panel of judges included “Eco Spindles
                        has created thousands of livelihoods for their local
                        communities”, “I love that sort of cycle of life. So
                        many lives are being transformed because the communities
                        are benefiting from that new way of recycling plastics”.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings PLC Managing Director and Chief Executive
                        Officer, Dr. Anush Amarasinghe, stated, “We are thankful
                        to IFS for this award as it serves as a reminder of the
                        responsibility undertaken to find effective and
                        permanent solutions to help preserve the natural
                        environment. Sustainability is at the core of every
                        business decision we take as we strive to reduce the
                        carbon footprint we make.”
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="https://news.cision.com/ifs/r/ifs-announces-winners-of-ifs-change-for-good-sustainability-awards-2021,c3425546">
                          https://news.cision.com/ifs/r/ifs-announces-winners-of-ifs-change-for-good-sustainability-awards-2021,c3425546
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen18(!isOpen18)}
                          >
                            {isOpen18 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 11 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/052c925a-dranush-300x206-1-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      BPPL HOLDINGS:
                      <br />
                      ONLY SRI LANKAN COMPANY LISTED IN FORBES ASIA’S 200 ‘BEST
                      UNDER A BILLION’
                    </h2>
                    <p style={isOpen19 ? null : paragraphStyles19}>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings PLC, parent company of Eco Spindles has
                        been crowned as the only Sri Lankan company to have
                        entered Forbes Asia’s 200 ‘Best Under a Billion’ list.
                        The company is one of Southeast Asia’s largest brush
                        manufacturers and Sri Lanka’s largest plastics recycler
                        equipped with one of only two plants in the world
                        capable of creating polyester yarn directly from
                        recycled PET plastic flakes.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Forbes evaluated over 20,000 publicly traded companies
                        in the Asia-Pacific region this year with revenues of
                        under US Dollars 1 Billion and consistent top-and
                        bottom-line growth. Companies were selected based on a
                        composite score that incorporated their overall track
                        record of which 200 were selected.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        After overcoming pandemic-related obstacles, the Group
                        also concluded the fiscal year 2020/21 with remarkable
                        improvements in all key financial metrics. The
                        consolidated revenue of BPPL increased by 31%
                        year-on-year to LKR 3.4 billion. The Group Net Profit
                        After Tax increased by 23% year-on-year to LKR 497
                        million.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        To be recognized on an international platform such as
                        Forbes is an enormous win noted BPPL Holdings PLC
                        Managing Director and Chief Executive Officer, Dr. Anush
                        Amarasinghe (pictured).
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen19(!isOpen19)}
                          >
                            {isOpen19 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/0623660e-young-entrepreneur-300x264-1.png"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      HOW A YOUNG ENTREPRENEUR FINDS WORTH IN PLASTIC WASTE
                    </h2>
                    <p style={isOpen20 ? null : paragraphStyles20}>
                      <p className="other-initiatives-read-more-para">
                        As industrialisation and population increase, plastic
                        consumption on the island has seen a 16% increase, with
                        265,000 metric tons are consumed annually resulting in
                        the nation battling a threatening issue – the growth in
                        irresponsible plastic waste disposal/management.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Realising the damage this could cause, a young
                        entrepreneur, Pathum Niranjana,
                        <br />
                        started P&amp;L Industries based in Athurugiriya in 2011
                        at the age of 20 to collect PET (Polyethylene
                        Terephthalate) plastic and other plastics.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Youth Day, celebrated annually in August is an ideal
                        opportunity to showcase the actions of young people who
                        aspire to repair the earth we live in, through their
                        entrepreneurial decisions to solve the world’s most
                        pressing issues.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Every month, his team collects approximately 450,000 PET
                        bottles and 70-80 metric
                        <br />
                        tons of other plastics, which are sold to recyclers such
                        as Eco Spindles, Sri Lanka’s
                        <br />
                        largest plastic recycler to create value-added products.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="https://www.lankabusinessonline.com/opinion-young-entrepreneur-finds-worth-in-plastic-waste/">
                          https://www.lankabusinessonline.com/opinion-young-entrepreneur-finds-worth-in-plastic-waste
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen20(!isOpen20)}
                          >
                            {isOpen20 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 12 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/048d9075-pet-bottles-300x179-1-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES LEADS THE WAY IN PLASTIC RECYCLING, SPINS 90
                      M PET BOTTLES INTO YARN EACH YEAR
                    </h2>
                    <p style={isOpen21 ? null : paragraphStyles21}>
                      <p className="other-initiatives-read-more-para">
                        Sri Lanka’s largest plastic recycler, Eco Spindles, has
                        presented a sustainable solution to managing plastic
                        waste by recycling plastic bottles directly into yarn or
                        monofilaments.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles recycle and convert 90 million PET plastic
                        bottles to polyester yarn,
                        <br />
                        annually at its recycling facility, one of two plants
                        globally capable of manufacturing yarn directly from PET
                        plastic flakes.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles PET plastic to yarn conversion process
                        ensures lowest carbon footprint
                        <br />
                        globally with its unique flake using Partially Oriented
                        Yarn (POY) technology ensuring a sustainable future for
                        the apparel industry.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Using recycled plastic to create yarn, Eco Spindles has
                        created six signature products:
                        <br />
                        Ecos Green , Ecos Evolve, Ecos Oceanic, Ecos Shield,
                        Ecos Amy, and Ecos Chroma.
                        <br />
                        Its ‘Ecos Evolve’ yarn is world’s most sustainable
                        product in recycled polyester yarn category.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="https://ceylontoday.lk/news/eco-spindles-leads-the-way-in-plastic-recycling">
                          https://ceylontoday.lk/news/eco-spindles-leads-the-way-in-plastic-recycling
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen21(!isOpen21)}
                          >
                            {isOpen21 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/41188507-arugam-bay-3-2-1.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      A PLASTIC INTERVENTION IN ARUGAM BAY
                    </h2>
                    <p style={isOpen22 ? null : paragraphStyles22}>
                      <p className="other-initiatives-read-more-para">
                        Waste Less Arugam Bay (WLAB) is an entity focused on
                        building a green and sustainable
                        <br />
                        tourist destination in Arugam Bay. Founded in 2018 by
                        Hendrik Konzok, founder and director,
                        <br />
                        WLAB, operates a manufacturing company making bags and
                        fashion accessories from upcycled materials. WLAB is
                        also engaged in upcycling discarded plastic bottles.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Henry’s team collects about 5000 – 15,000 PET bottles
                        discarded irresponsibly onto roads and waterways.
                        Knowing the value WLAB creates in Arugam Bay, Eco
                        Spindles also provides recycled polyester yarn that WLAB
                        sources to make tote bags out of recycled plastic.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="http://dailynews.lk/2021/07/16/tc/254077/plastic-intervention-arugam-bay">
                          http://dailynews.lk/2021/07/16/tc/254077/plastic-intervention-arugam-bay
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen22(!isOpen22)}
                          >
                            {isOpen22 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 13 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/49e338ec-mistreated-ocean-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      A MISTREATED OCEAN’S CRY FOR HELP
                    </h2>
                    <p style={isOpen23 ? null : paragraphStyles23}>
                      <p className="other-initiatives-read-more-para">
                        In the last decade, Sri Lanka has mismanaged 1.59
                        million metric tons of plastic waste yearly that
                        ultimately finds its way to the ocean. Seven out of the
                        12 Indian Ocean rim countries are significant plastic
                        polluters, Sri Lanka included. The recent MV X-Press
                        Pearl container explosion has further amplified issues
                        around marine pollution.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Appropriately discarding the plastic waste is critical.
                        Ensuring that the collected waste is recycled is even
                        more important. Plastic bottles collected from across
                        the island–beaches, collection centers, and the ocean –
                        is brought into Eco Spindles’ recycling facility in
                        Horana. This facility recycles approximately
                        7,500,000-9,000,000 bottles of PET plastic waste per
                        month.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles, as a recycling giant leverages technology
                        to keep count of the plastic waste collected from the
                        beaches. Using an app, collectors input the collection
                        numbers ensuring Eco
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Spindles can trace the amount of plastic collected.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="http://bizenglish.adaderana.lk/a-mistreated-oceans-cry-for-help/">
                          http://bizenglish.adaderana.lk/a-mistreated-oceans-cry-for-help/
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen23(!isOpen23)}
                          >
                            {isOpen23 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/0b133265-dispose-plastic-waste-300x233-1-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      TIME TO RESTORE: BPPL GROUP URGES SRI LANKANS TO DISPOSE
                      PLASTIC WASTE RESPONSIBLY THIS WORLD ENVIRONMENT DAY
                    </h2>
                    <p style={isOpen24 ? null : paragraphStyles24}>
                      <p className="other-initiatives-read-more-para">
                        BPPL Holdings PLC and subsidiary Eco Spindles, Sri
                        Lanka’s largest plastic recycler has called for the
                        responsible disposal of plastic waste across the island
                        as the globe commemorates World Environment Day.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        This year’s theme, “Ecosystem Restoration” is focused on
                        implementing a solution to a depleting ecosystem, an
                        urgent issue faced in Sri Lanka, and across the world.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Aligning with this year’s theme and playing a part as a
                        responsible organization, the BPPL
                        <br />
                        Group has engaged in multiple waste management
                        initiatives over the past five years. Key highlights are
                        the collection initiatives at Sri Pada, Kataragama and
                        Thalawila, three sites of religious worship that attract
                        crowds from across the island.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="http://bizenglish.adaderana.lk/time-to-restore-bppl-group-urges-sri-lankans-to-dispose-plastic-waste-responsibly-this-world-environment-day/">
                          http://bizenglish.adaderana.lk/time-to-restore-bppl-group-urges-sri-lankans-to-dispose-plastic-waste-responsibly-this-world-environment-day/
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen24(!isOpen24)}
                          >
                            {isOpen24 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 14 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/ecospindles-uploads_63fb2f2d-raging-pandemic.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      A PET COLLECTOR’S JOURNEY AMID A RAGING PANDEMIC
                    </h2>
                    <p style={isOpen25 ? null : paragraphStyles25}>
                      <p className="other-initiatives-read-more-para">
                        Though Sri Lanka has seen an increase in recyclers and
                        plastic waste collectors, Covid-19 has presented a new
                        set of challenges.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Mahesh, a collector, has created a living by collecting
                        post-consumer plastic waste (PET and HDPE) from hotels
                        and hospitals in Galle. He has been in the waste
                        collection industry for ten years, which has helped him
                        support his family, including his two children in grades
                        8 and 2.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Currently, he collects enough PET to fill two Tata
                        lorries because of hotel closures, equaling 1,000 kgs a
                        month. Before Covid-19, he collected and sent four
                        loads, approximately 2,500-4,000 kgs of PET per month
                        supplying Eco Spindles, Sri Lanka’s biggest recycler.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Mahesh’s story is one among the over 200 collectors in
                        Sri Lanka. Making sure that plastic ends up in recycling
                        centers is more than just a job for Mahesh. He is doing
                        his part to ensure that leftovers waste is recycled into
                        new life.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="http://www.sundayobserver.lk/2021/05/30/business/pet-collector%e2%80%99s-journey-amid-raging-pandemic">
                          http://www.sundayobserver.lk/2021/05/30/business/pet-collector%e2%80%99s-journey-amid-raging-pandemic
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen25(!isOpen25)}
                          >
                            {isOpen25 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/cdn.ecospindles.com-uploads_8fcfcca1-eco-spindles-ranked-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES RANKED AMONG TOP 10 RECYCLERS GLOBALLY BY THE
                      GLOBAL RECYCLING FOUNDATION
                    </h2>
                    <p style={isOpen26 ? null : paragraphStyles26}>
                      <p className="other-initiatives-read-more-para">
                        Sri Lanka’s leading plastic recycler Eco Spindles (Pvt.)
                        Ltd, a fully-owned subsidiary of BPPL Holdings PLC, was
                        ranked among the Global Recycling Foundation’s top ten
                        #RecyclingHeroes of 2021.
                        <br />
                        This ranking was alongside nine other contributors in
                        the global recycling arena, namely, Rien Voets
                        (Holland), Green Axis (Nigeria), Abdi Hirsi (Somalia),
                        Bokashi Bran (Pty) Ltd (South Africa), EcoAct
                        (Tanzania), International WeLoveU Foundation (Korea),
                        Dgrade (Dubai), Eco-Train (Canada), and Metal Shredder
                        (Hungary).
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The Global Recycling Foundation supports the promotion
                        of recycling, and the recycling industry, across the
                        world to showcase its vital role in preserving the
                        future of the planet. The #RecyclingHeroes competition
                        was initiated in February last year and encourages
                        people from across the globe to nominate individuals,
                        businesses or communities that are setting examples and
                        pushing boundaries with their recycling initiatives.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="http://bizenglish.adaderana.lk/eco-spindles-ranked-among-top-10-recyclers-globally-by-the-global-recycling-foundation/">
                          http://bizenglish.adaderana.lk/eco-spindles-ranked-among-top-10-recyclers-globally-by-the-global-recycling-foundation/
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen26(!isOpen26)}
                          >
                            {isOpen26 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 15 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/30c4aec5-waste-2-value-2.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES LAUNCHES ‘WASTE 2 VALUE’ APP ON GLOBAL
                      RECYCLINGDAY
                    </h2>
                    <p style={isOpen27 ? null : paragraphStyles27}>
                      <p className="other-initiatives-read-more-para">
                        Eco Spindles (Pvt.) Ltd, a fully-owned subsidiary of
                        BPPL Holdings PLC, launched its highly anticipated
                        ‘Waste 2 Value’ app at a special event. The app launch
                        coincided with ‘Global Recycling Day’ and is aimed at
                        encouraging responsible plastic recycling among the
                        general public and consolidate recyclable waste
                        collection efforts
                        <br />
                        within the country.
                        <br />
                        Importantly, the app was launched to help citizens
                        locate bins more conveniently, enabling the disposal of
                        their plastic waste properly. The app is capable of
                        locating over 300 plastic waste bins around the island.
                        <br />
                        The app can be downloaded via Google Play Store for
                        Android and App Store for iOS and
                        <br />
                        easily locates the nearest plastic waste bin.
                        <br />
                        During 2019/20, Eco Spindles had recycled over 81
                        million PET bottles, amounting to around 2.3 million
                        kilograms of PET sourced mainly through the company’s
                        400-strong network of
                        <br />
                        registered recyclable waste collectors and 19 baling
                        sites.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Read More –{" "}
                        <a href="https://www.dailynews.lk/2021/03/23/business/244708/eco-spindles-launches-%E2%80%98waste-2-value%E2%80%99-app-global-recycling-day">
                          https://www.dailynews.lk/2021/03/23/business/244708/eco-spindles-launches-%E2%80%98waste-2-value%E2%80%99-app-global-recycling-day
                        </a>
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen27(!isOpen27)}
                          >
                            {isOpen27 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/Polyester_Yarn_Facility_2_-768x661-1.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO-SPINDLES BREAKS GROUND ON NEW STATE-OF-THE-ART
                      RECYCLED POLYESTER YARN FACILITY
                    </h2>
                    <p style={isOpen28 ? null : paragraphStyles28}>
                      <p className="other-initiatives-read-more-para">
                        Eco-Spindles (Pvt.) Ltd commenced phase 2 of its
                        expansion strategy to add 20,000 sq.ft of production
                        space at its state-of-the-art facility in Horana.
                        Already one of only two such facilities in the entire
                        world, once completed, the expanded facility will double
                        polyester yarn production capacity at Eco-Spindles.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        The foundation stone was laid by Secretary to the
                        Ministry of Environment Dr. Anil Jasinghe together with
                        Central Environmental Authority Director General P.B.
                        Hemantha Jayasinghe, Board of Investment of Sri Lanka
                        Horana Export Processing Zone Senior Deputy Director
                        Sujeewa Thilakumara, Ministry of Mahaweli Development
                        and Environment, Environmental Pollution Control and
                        Chemical Management Division Director S.M. Werahera,
                        BPPL Holdings Managing Director and Chief Executive
                        Officer Dr. Anush Amarasinghe and Eco-Spindles (Pvt.)
                        Ltd Deputy General Manager Manoj Udawatte, in the
                        presence of other senior representatives from
                        Eco-Spindles at a special ceremony held on the 8th of
                        February, 2021.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen28(!isOpen28)}
                          >
                            {isOpen28 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* //// 16 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/DEE_3214-New.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO-SPINDLES, HNB AND IFS COME TOGETHER FOR A PLASTIC
                      BOTTLE RECYCLING CAMPAIGN ACROSS SCHOOLS AROUND COLOMBO
                    </h2>
                    <p style={isOpen29 ? null : paragraphStyles29}>
                      <p className="other-initiatives-read-more-para">
                        Did you know: A typical Colombo household generates 3kg
                        of PET waste every year. If disposed improperly, this
                        waste will have an incredibly negative impact on the
                        environment.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        To take on this problem, HNB and IFS established
                        collection bins across HNB’s school network in Colombo.
                        We handle the recycling network, collecting and
                        transporting the waste to our recycling plants and then
                        convert it into valuable materials for products
                        worldwide.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        Our CEO, Dr. Amarasinghe, said that this campaign was
                        for the purpose of “inculcating the habit of recycling
                        amongst the younger generations, through numerous
                        interactive awareness programmes”, creating a
                        “substantial change in their mindset” and offering Sri
                        Lanka a long term solution to plastic pollution.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        One day, all PET bottles will be responsibly recycled.
                        With initiatives such as this, we take one step closer
                        to reaching this goal.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen29(!isOpen29)}
                          >
                            {isOpen29 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">
                {/* Column 2 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/IMG_8178.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES PARTNERS WITH JOHN KEELLS GROUP’S
                      ‘PLASTICCYCLE’, WALKERS TOURS AND ROAD DEVELOPMENT
                      AUTHORITY
                    </h2>
                    {/* <h3 className="other-initiatives-2nd-topic"></h3> */}
                    <p style={isOpen30 ? null : paragraphStyles30}>
                      <p className="other-initiatives-read-more-para">
                        The convenience of plastic for packaging means a large
                        amount of waste generated by people on the go. Sri
                        Lanka’s highways are often littered with plastic
                        discarded by commuters.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        In July 2017, Plasticcycle was launched by the Social
                        Entrepreneurship initiative of the John Keells Group
                        with the vision of significantly reducing plastic
                        pollution in Sri Lanka. We collaborated with
                        Plasticcycle and the Road Development Authority to
                        collect and recycle PET bottles from commuters on the
                        Southern Expressway.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        With the help of Plasticcycle, we placed 82 branded bins
                        at key expressway exits to promote the responsible
                        disposal of PET bottles. The Road Development Authority
                        collects plastics from these bins, and we bring the
                        collection to our recycling plants.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        To date, this project has eliminated 5400 tons of
                        plastic from polluting the Southern Expressway. With the
                        success this project has seen, we hope to expand our
                        collection network to all other major roadways in the
                        near future.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen30(!isOpen30)}
                          >
                            {isOpen30 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
                {/* <img
                    src="/images/other-initiatives/IMG_8178.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      ECO SPINDLES PARTNERS WITH JOHN KEELLS GROUP’S ‘PLASTICCYCLE’, WALKERS TOURS AND ROAD DEVELOPMENT AUTHORITY
                    </h2>
                    <h3 className="other-initiatives-2nd-topic"></h3>
                    <p style={isOpen24 ? null : paragraphStyles24}>
                      <p className="other-initiatives-read-more-para">
                        The convenience of plastic for packaging means a large amount of waste generated by people on the go. Sri Lanka’s highways are often littered with plastic discarded by commuters.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        In July 2017, Plasticcycle was launched by the Social Entrepreneurship initiative of the John Keells Group with the vision of significantly reducing plastic pollution in Sri Lanka. We collaborated with Plasticcycle and the Road Development Authority to collect and recycle PET bottles from commuters on the Southern Expressway.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        With the help of Plasticcycle, we placed 82 branded bins at key expressway exits to promote the responsible disposal of PET bottles. The Road Development Authority collects plastics from these bins, and we bring the collection to our recycling plants.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        To date, this project has eliminated 5400 tons of plastic from polluting the Southern Expressway. With the success this project has seen, we hope to expand our collection network to all other major roadways in the near future.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen24(!isOpen24)}
                          >
                            {isOpen24 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div> */}
              </div>
            </div>
          </div>

          {/* //// 17 //// */}
          <div>
            <div className="two-column-para">
              <div className="column-para column1-para">
                {/* Column 1 */}
                <div style={{ padding: "10px" }}>
                  <img
                    src="/images/other-initiatives/500_school-768x512-1.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="other-initiatives-topic">
                      500 SCHOOLS, 500 FUTURES
                    </h2>
                    <h3 className="other-initiatives-2nd-topic"></h3>
                    <p style={isOpen23 ? null : paragraphStyles23}>
                      <p className="other-initiatives-read-more-para">
                        In rural areas, poor disposal of plastic waste is an
                        oft-ignored issue due to the remoteness of these
                        communities. We took on this problem by partnering with
                        Coca-Cola and are beginning to install 500, 25kg
                        collection bins in 500 schools in the Central, Uwa, and
                        North provinces of Sri Lanka.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        650,000 students (and their families) now have access to
                        these recycling bins, achieving an impact on waste that
                        is rarely seen there. We went a step further by hosting
                        training and education programs, teaching students the
                        methods and importance of proper plastic disposal.
                      </p>
                      <p className="other-initiatives-read-more-para">
                        By providing all this, we teach our next generations how
                        to live sustainably with an eye for waste reduction. We
                        found that this effect had a wide reach, going as deep
                        as local families who learn from what is taught to their
                        children. The collection network supported by local
                        authorities brings together both public and private
                        sectors in a common goal of environmental protection.
                      </p>

                      <br />
                    </p>
                    <center>
                      <div className="product-yarn-button-container-2">
                        <div className="read-more-bn-background-2">
                          <a
                            className="read-more-bn"
                            onClick={() => setIsOpen23(!isOpen23)}
                          >
                            {isOpen23 ? (
                              <>
                                Read less <FontAwesomeIcon icon={faArrowUp} />
                              </>
                            ) : (
                              <>
                                Read more <FontAwesomeIcon icon={faArrowDown} />
                              </>
                            )}
                          </a>
                        </div>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="column-para column2-para">{/* Column 2 */}</div>
            </div>
          </div>

          {/* /////////// end ///////// */}
        </div>
      </center>

      <Footer />
    </div>
  );
}
